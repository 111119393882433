import React, { FC, useEffect, useState } from 'react'

import { EyeIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import CoursePlayerView from 'src/components/Learner/Courses/CourseCell/CoursePlayer/TaskCell/CoursePlayerView/CoursePlayerView'
import Switch from 'src/components/Library/Switch'
import Modal from 'src/components/Modal/Modal'
import SavedStatus from 'src/components/SavedStatus/SavedStatus'
import useBoolean from 'src/lib/hooks/UseBoolean'
import { published } from 'src/Util'

interface FormHeaderProps {
  task: any
  onDeleteTask(id: any, name: any)
  togglePublished(learnerItem: any)
  editing: boolean
  saving: boolean
  disablePublish: boolean
  lessonTypeText: string
}

const FormHeader: FC<FormHeaderProps> = ({
  task,
  onDeleteTask,
  togglePublished,
  editing,
  saving,
  disablePublish,
  lessonTypeText,
}) => {
  const { value, setFalse, toggle } = useBoolean(false)
  const [disabledReason, setDisabledReason] = useState('Unable to publish')

  useEffect(() => {
    if (!disablePublish) return

    let disabledReasonText = 'Unable to publish'
    if (
      lessonTypeText === 'Video' ||
      lessonTypeText === 'Text' ||
      lessonTypeText === 'Embed'
    ) {
      disabledReasonText = 'Please add content'
    } else if (lessonTypeText === 'Quiz') {
      disabledReasonText = 'Please correct quiz'
    } else if (lessonTypeText === 'Process Map') {
      disabledReasonText = 'Please select a Map'
    }

    setDisabledReason(disabledReasonText)
  }, [lessonTypeText])

  return (
    <>
      <div className="mr-4 pl-10">
        <div className="ml-4 flex flex-wrap items-center justify-between border-b-2 border-gray-200 pb-2">
          <div
            className="font-inter mr-5 box-border flex items-center border-0 border-b-0 border-solid border-gray-300 text-xl font-medium leading-6 text-gray-900"
            key={task?.id}
            id={'courseName' + task?.id}
          >
            {task?.name}
            {lessonTypeText !== 'Quiz' && lessonTypeText !== 'Process Map' && (
              <Tooltip title={'Preview Lesson'}>
                <div>
                  <IconButton
                    disabled={disablePublish}
                    aria-label="delete"
                    onClick={toggle}
                    size="medium"
                    className="ml-2"
                  >
                    <EyeIcon className="h-6 w-6 text-blue-500" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
          <div className="flex flex-row items-center justify-end">
            <Tooltip
              title={
                disablePublish
                  ? disabledReason
                  : published(task)
                    ? 'Published'
                    : 'Unpublished'
              }
            >
              <div>
                <Switch
                  size="small"
                  checked={published(task)}
                  onChange={() => {
                    if (published(task) || !disablePublish) {
                      togglePublished(task)
                    }
                  }}
                  disabled={disablePublish} // if video or text, make sure at least one of the fields has data
                />
              </div>
            </Tooltip>

            <SavedStatus saving={saving} editing={editing} />
            <Tooltip title={'Delete Lesson'}>
              <IconButton
                aria-label="delete"
                onClick={() => onDeleteTask(task.id, task.name)}
                size="medium"
              >
                <TrashIcon className="h-6 w-6 text-red-500" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Modal open={value} onClose={setFalse} disablePortal={false}>
          <div className="p-4">
            <CoursePlayerView
              task={task}
              setTaskStatus={undefined}
              isPreview={true}
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default FormHeader
