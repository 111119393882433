import { FC, useState, useEffect } from 'react'

import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
  EditLearnerTaskById,
  EditLearnerTaskByIdVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import { EDIT_LEARNER_TASK_BY_ID } from 'src/lib/queries/learner/learnerTask'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { reorderItems, nextRank } from 'src/Util'
import { useAuth } from 'web/src/Providers'

import { LearnerCourseRefetch } from '../../LearnerCourse/EditLearnerCourseCell'
import EditLearnerQuestionCell from '../EditLearnerQuestionCell'

type LearnerQuestion = EditLearnerTaskById['learnerTask']['quiz'][0]
interface LearnerQuizFormProps {
  learnerTaskId: number
  questions: LearnerQuestion[]
  refetch: LearnerCourseRefetch
}

export const CREATE_LEARNER_QUESTION_MUTATION = gql`
  mutation CreateLearnerQuestionSettingMutation(
    $input: CreateLearnerQuestionInput!
  ) {
    createLearnerQuestion(input: $input) {
      id
      rank
    }
  }
`

const LearnerQuizForm: FC<LearnerQuizFormProps> = ({
  learnerTaskId,
  questions = [],
  refetch,
}) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id
  const [isUpdating, setIsUpdating] = useState(false)
  const [learnerQuestions, setLearnerQuestions] = useState(questions)
  const [orderedLearnerQuestions, setOrderedQuestions] = useState<any[]>()

  useQuery<EditLearnerTaskById, EditLearnerTaskByIdVariables>(
    EDIT_LEARNER_TASK_BY_ID,
    {
      variables: {
        id: learnerTaskId,
      },
      skip: !learnerTaskId,
      onCompleted: (data) => {
        setLearnerQuestions(data?.learnerTask.quiz)
      },
    },
  )

  const [createLearnerQuestion] = useMutation(
    CREATE_LEARNER_QUESTION_MUTATION,
    {
      onCompleted: async () => {
        await refetch()
        toast.success('Question created')
      },

      onError: (error) => {
        toast.error(error.message)
      },
    },
  )

  const [rankLearnerItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: async () => {
      await refetch()
      setIsUpdating(false)
      setOrderedQuestions(null)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (orderedLearnerQuestions) {
      // setIsUpdating to true as a stop to disallow further changes until after the mutation completes
      setIsUpdating(true)
      const reorderedItemsInput = orderedLearnerQuestions.map(
        (item, index) => ({
          id: item.id,
          rank: index + 1,
        }),
      )
      rankLearnerItems({
        variables: {
          input: { itemType: 'Question', items: reorderedItemsInput },
        },
      })
    }
  }, [clientId, orderedLearnerQuestions, rankLearnerItems])

  const onQuestionDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || isUpdating) {
      return
    }
    const reorderedItems = reorderItems(
      learnerQuestions,
      result.source.index,
      result.destination.index,
    )
    setLearnerQuestions(reorderedItems)
    setOrderedQuestions(reorderedItems)
  }

  const addNewQuestion = async () => {
    await createLearnerQuestion({
      variables: {
        input: {
          question: 'New Question',
          rank: nextRank(learnerQuestions),
          choices: [
            {
              text: 'New Option',
              rank: 1,
              isAnswer: false,
            },
          ],
          clientId,
          required: false,
          learnerTaskId: learnerTaskId,
        },
      },
    })
  }

  return (
    <>
      <DragDropContext onDragEnd={(e) => onQuestionDragEnd(e)}>
        <Droppable droppableId={learnerTaskId + ''}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {learnerQuestions.map(
                (question: LearnerQuestion, index: number) => (
                  <EditLearnerQuestionCell
                    questionIndex={index}
                    key={question.id}
                    questionId={question.id}
                    isMultipleQuestions={learnerQuestions.length > 1} //TODO set delete question only if more than one question
                    refetch={refetch}
                  />
                ),
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        variant="outlined"
        onClick={addNewQuestion}
        endIcon={<PlusCircleIcon className="h-6 w-6" />}
      >
        Add Question
      </Button>
    </>
  )
}

export default LearnerQuizForm
