import React, { FC, useEffect, useState } from 'react'

import { Grid2 as Grid, TextField as MuiTextField } from '@mui/material'
import { LearnerTask } from 'types/graphql'

import { Form, Label } from '@redwoodjs/forms'

import DebounceTextInput from 'src/components/DebounceTextInput/DebounceTextInput'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Switch from 'src/components/Library/Switch'
import { LearnerCourseRefetch } from 'src/components/Settings/LearnerCourse/EditLearnerCourseCell'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import LearnerQuizForm from '../../../LearnerQuestion/LearnerQuizForm/LearnerQuizForm'

interface Option {
  id: number
  name?: string
}

interface Data {
  learnerActivityId?: number
  name?: string
  showQuizAnswers?: boolean
}

interface QuizTaskFormProps {
  learnerTask: LearnerTask
  error?: Error
  loading?: boolean
  onSave(input: any, id: number)
  setSaving(bool: boolean)
  setEditing(bool: boolean)
  options: Option[]
  activityIndex: number
  refetch: LearnerCourseRefetch
}

const QuizTaskForm: FC<QuizTaskFormProps> = ({
  learnerTask,
  setEditing,
  options,
  activityIndex,
  refetch,
  setSaving,
  onSave,
}) => {
  const [lessonTitle, setLessonTitle] = useState<string>('')
  const [chapterValue, setChapterValue] = React.useState<Option | null>(
    options[activityIndex],
  )
  const [selectedActivityId, setSelectedActivityId] = useState<number>(-1)
  const [updateData, setUpdateData] = useState<boolean>(false)
  const [showAnswersToggle, setShowAnswersToggle] = useState<boolean>(true)
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    // initialize the title
    if (learnerTask?.name && lessonTitle !== learnerTask.name) {
      setLessonTitle(learnerTask.name)
    }
  }, [learnerTask?.name, selectedActivityId])

  useEffect(() => {
    // initialize the toggle
    if (typeof learnerTask?.showQuizAnswers === 'undefined') return
    setShowAnswersToggle(learnerTask?.showQuizAnswers)
  }, [learnerTask?.showQuizAnswers])

  const handleToggle = () => {
    setShowAnswersToggle(!showAnswersToggle)
    setUpdateData(true)

    trackEvent('Learner', 'show answers on completion', {
      toggleVal: !showAnswersToggle,
    })
  }

  const handleTitleChange = (value) => {
    setLessonTitle(value)
    setUpdateData(true)
  }

  const onChange = () => {
    const data: Data = {}

    if (selectedActivityId === -1) {
      data.learnerActivityId = learnerTask?.learnerActivityId
    } else {
      data.learnerActivityId = selectedActivityId
    }

    data.name = lessonTitle || ''

    data.showQuizAnswers = showAnswersToggle

    if (learnerTask) {
      if (
        learnerTask.learnerActivityId === data?.learnerActivityId &&
        learnerTask.name === data.name &&
        learnerTask.showQuizAnswers === data.showQuizAnswers
      ) {
        setEditing(false)
        return
      }

      setSaving(true)
      setEditing(false)
      onSave(data, learnerTask?.id)
    }
    setSelectedActivityId(-1)
    setUpdateData(false)
  }

  useEffect(() => {
    if (updateData) {
      setEditing(true)
      onChange()
    }
  }, [updateData])

  // prevent save on lesson change
  useEffect(() => {
    setUpdateData(false)
  }, [learnerTask?.id])

  return (
    <div className="px-4 pl-14">
      <Form>
        <Grid container rowSpacing={1} columnSpacing={0.5}>
          <Grid size={{ xs: 2 }}>
            <Label name="learnerActivityTitle" className="rw-label">
              Title
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <DebounceTextInput
              setKey={learnerTask?.id}
              name="title"
              placeholder="Title of the lesson"
              className="mt-4"
              defaultValue={lessonTitle}
              debounceTimeout={1500}
              onChange={(value) => {
                return value !== learnerTask?.name
                  ? handleTitleChange(value)
                  : null
              }}
              isRequired={true}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Label
              name="learnerActivityId"
              className="rw-label"
              errorClassName="rw-label rw-label-error"
            >
              Chapter
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }} className="mt-4">
            <Autocomplete
              sx={{ '& .MuiAutocomplete-input': { height: 5 } }}
              key={learnerTask?.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disablePortal
              onChange={(_, newValue: Option) => {
                setSelectedActivityId(newValue.id)
                setChapterValue(newValue) // Update the chapterValue state here
                setUpdateData(true)
              }}
              value={chapterValue} // Use chapterValue as the value
              id="combo-box-demo"
              options={options}
              getOptionLabel={(option: Option) => option.name}
              defaultValue={chapterValue} // Use chapterValue as the defaultValue
              renderInput={(params) => <MuiTextField {...params} />}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <Label
              name="learnerActivityId"
              className="rw-label"
              errorClassName="rw-label rw-label-error"
            >
              Show answers on completion?
            </Label>
          </Grid>
          <Grid size={{ xs: 8 }} className="mt-7">
            <Switch
              size="small"
              checked={showAnswersToggle}
              onChange={handleToggle}
            />
          </Grid>
          <Grid size={{ xs: 12 }} className="mt-4">
            <LearnerQuizForm
              learnerTaskId={learnerTask?.id}
              questions={learnerTask?.quiz as any}
              refetch={refetch}
            />
          </Grid>
        </Grid>
      </Form>
    </div>
  )
}

export default QuizTaskForm
