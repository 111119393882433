import React, { useState } from 'react'

import { Label } from '@redwoodjs/forms'

import LearnerFullWidthIcon from 'src/lib/icons/LearnerFullWidthIcon/LearnerFullWidthIcon'
import LearnerTranscriptIcon from 'src/lib/icons/LearnerTranscriptIcon/LearnerTranscriptIcon'

import Button from '../Library/Button/Button'

interface LessonTextLayoutOptionsProps {
  textLayout: string
  onChange: (newTextLayout: string) => void
}

const LessonTextLayoutOptions: React.FC<LessonTextLayoutOptionsProps> = ({
  textLayout,
  onChange,
}) => {
  const [fullWidthHovered, setFullWidthHovered] = useState(false)
  const [transcriptHovered, setTranscriptHovered] = useState(false)
  return (
    <div className="bg-gray-100 w-full h-[175px] mt-4 p-4 flex items-start justify-start rounded-md">
      <div className="h-[175px] w-[173px]">
        <Label
          name="text-layout"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Text Layout
        </Label>
        <Label
          name="text-layout-optional"
          className="rw-label mt-1 text-xs text-gray-400"
        >
          Optional
        </Label>
      </div>
      <div className="h-[175px] flex flex-col items-center">
        <Button
          variant="text"
          className={`border-2 ${
            (textLayout === 'fullWidth' && !transcriptHovered) ||
            (textLayout !== 'fullWidth' && fullWidthHovered)
              ? 'border-indigo-600'
              : 'border-transparent'
          } transform-gpu transition-all duration-300 hover:scale-105 hover:shadow-lg rounded`}
          onClick={() => onChange('fullWidth')}
          onMouseEnter={() => setFullWidthHovered(true)}
          onMouseLeave={() => setFullWidthHovered(false)}
        >
          <LearnerFullWidthIcon textLayout={textLayout} />
        </Button>
        <Label
          name="transcript-label"
          className="rw-label mt-2 text-xs text-gray-400"
        >
          Full-Width
        </Label>
      </div>
      <div className="h-[175px] pl-3 flex flex-col items-center">
        <Button
          variant="text"
          className={`border-2 ${
            (textLayout === 'transcript' && !fullWidthHovered) ||
            (textLayout !== 'transcript' && transcriptHovered)
              ? 'border-indigo-600'
              : 'border-transparent'
          } transform-gpu transition-all duration-300 hover:scale-105 hover:shadow-lg rounded`}
          onClick={() => onChange('transcript')}
          onMouseEnter={() => setTranscriptHovered(true)}
          onMouseLeave={() => setTranscriptHovered(false)}
        >
          <LearnerTranscriptIcon textLayout={textLayout} />
        </Button>
        <Label
          name="transcript-label"
          className="rw-label mt-2 text-xs text-gray-400"
        >
          Transcript
        </Label>
      </div>
    </div>
  )
}

export default LessonTextLayoutOptions
