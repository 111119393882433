import React from 'react'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { reorderItems } from 'src/Util'

import { LearnerCourseRefetch } from '../../EditLearnerCourseCell'
import DraggableChapter from '../DraggableChapter/DraggableChapter'

interface Props {
  chapters: any
  setChapters: any
  setOrderedChapters: any
  isUpdating: boolean
  selectedActivity: any
  setSelectedActivity: any
  refetch: LearnerCourseRefetch
  onNewLesson(chapter: any)
  onSelectedLesson(lesson: any, chapter: any)
  onSelectedChapter(chapter: any)
  view: any
}

const ChapterDragAndDrop: React.FC<Props> = ({
  refetch,
  chapters,
  setChapters,
  setOrderedChapters,
  isUpdating,
  onNewLesson,
  onSelectedChapter,
  selectedActivity,
  onSelectedLesson,
  view,
}) => {
  const onChapterDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    if (isUpdating) {
      return
    }

    const reorderedItems = reorderItems(
      chapters,
      result.source.index,
      result.destination.index,
    )

    setChapters(reorderedItems)
    setOrderedChapters(reorderedItems)
  }

  return (
    <>
      <DragDropContext onDragEnd={onChapterDragEnd}>
        <Droppable droppableId={(selectedActivity?.id ?? '0') + ''}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {chapters?.map((chapter, chapterIndex) => (
                <DraggableChapter
                  key={chapter.id}
                  refetch={refetch}
                  chapter={chapter}
                  chapterIndex={chapterIndex}
                  selected={selectedActivity}
                  onNewLesson={onNewLesson}
                  onSelectedLesson={onSelectedLesson}
                  onSelectedChapter={onSelectedChapter}
                  view={view}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export default ChapterDragAndDrop
