import React, { useEffect, useState, useRef } from 'react'

import {
  DocumentTextIcon,
  SparklesIcon,
  TrashIcon,
  VideoCameraIcon,
  MapIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import type {
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import DragHandle from 'src/components/DragHandle/DragHandle'
import Empty from 'src/components/Empty/Empty'
import IconButton from 'src/components/IconButton/IconButton'
import InPlaceEditor from 'src/components/Library/InPlaceEditor/InPlaceEditor'
import Switch from 'src/components/Library/Switch'
import { LearnerCourseRefetch } from 'src/components/Settings/LearnerCourse/EditLearnerCourseCell'
import Status from 'src/components/Status/Status'
import Tag from 'src/components/Tag/Tag'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { published, getDraggableItemStyle, reorderItems } from 'src/Util'

interface ActivityDetailsProps {
  activity: any
  togglePublished: any
  onDeleteActivity: any
  onDeleteTask: any
  updateActivityName: any
  onSelectedLesson: any
  refetch: LearnerCourseRefetch
}

const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  activity,
  togglePublished,
  onDeleteActivity,
  onDeleteTask,
  updateActivityName,
  onSelectedLesson,
  refetch,
}) => {
  const [selected, setSelected] = useState<any>()
  const [checked, setChecked] = useState(false)
  const [learnerTasks, setLearnerTasks] = useState(activity?.learnerTasks)
  const [orderedLearnerTasks, setOrderedLearnerTasks] = useState<
    any[] | undefined
  >()

  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setLearnerTasks(activity?.learnerTasks)
  }, [activity.learnerTasks])

  const [rankLearnerItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: async () => {
      await refetch()
      setIsUpdating(false)
      setOrderedLearnerTasks(null)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (orderedLearnerTasks) {
      // setIsUpdating to true as a stop to disallow further changes until after the mutation completes
      setIsUpdating(true)
      const reorderedItemsInput = orderedLearnerTasks.map((item, index) => ({
        id: item.id,
        rank: index + 1,
      }))
      rankLearnerItems({
        variables: {
          input: { itemType: 'Task', items: reorderedItemsInput },
        },
      })
    }
  }, [orderedLearnerTasks])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || isUpdating) {
      return
    }

    const reorderedItems = reorderItems(
      learnerTasks,
      result.source.index,
      result.destination.index,
    )

    setLearnerTasks(reorderedItems)
    setOrderedLearnerTasks(reorderedItems)
  }

  useEffect(() => {
    setSelected(activity)
    if (activity) {
      setChecked(published(activity))
    }
  }, [activity])

  const toggleSwitch = () => {
    setChecked(!checked)
    togglePublished(activity)
  }

  const useDraggableInPortal = () => {
    const self: any = useRef({}).current

    useEffect(() => {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.pointerEvents = 'none'
      div.style.top = '0'
      div.style.width = '100%'
      div.style.height = '100%'
      self.elt = div
      document.body.appendChild(div)
      return () => {
        document.body.removeChild(div)
      }
    }, [self])

    return (render) =>
      (provided, ...args) => {
        const element = render(provided, ...args)
        if (provided.draggableProps.style.position === 'fixed') {
          return createPortal(element, self.elt)
        }
        return element
      }
  }

  const renderDraggable = useDraggableInPortal()

  if (!selected) {
    return (
      <div className="flex h-full">
        <div className="m-auto">
          <Empty
            learnerCourse
            title="No activity"
            description="Please select an activity"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col">
      <style>
        {`
        .e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              border-bottom: none;
          }
        .e-inplaceeditor .e-editable-value-wrapper {
              background: #f3f4f6 !important;
              border-radius: 4px !important;
            }
        .e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon {
          visibility: visible !important;
            }
        `}
      </style>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between">
          <div className="ml-4 flex justify-start">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              <InPlaceEditor
                id={'activity' + activity?.id}
                value={activity?.name}
                onChange={(newName) =>
                  updateActivityName(activity?.id, newName)
                }
                title="Change Title"
              />
            </h3>
          </div>
          <div className="flex flex-row items-center justify-end">
            <IconButton
              aria-label="delete"
              className="mr-3 h-5 w-5"
              danger
              onClick={() => onDeleteActivity(selected.id, selected.name)}
            >
              <TrashIcon className="h-5 w-5 text-red-500" />
            </IconButton>

            <Switch
              checked={checked}
              onChange={toggleSwitch}
              title={checked ? 'Published' : 'Unpublished'}
              size="small"
            />
            <Status published={checked} />
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {activity.learnerTasks?.length > 1 ? 'Lessons' : 'Lesson'}
            </h3>
          </div>
        </div>
      </div>
      <div className="h-max-[500px] h-[350px] overflow-y-auto">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={(activity?.id ?? '0') + ''}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {learnerTasks?.map((lesson, lessonIndex) => (
                  <Draggable
                    key={lesson.id}
                    draggableId={lesson.id + ''}
                    index={lessonIndex}
                  >
                    {renderDraggable((provided, snapshot) => (
                      <div
                        className="divide-gray-20 divide-y px-3"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getDraggableItemStyle(
                          provided.draggableProps.style,
                        )}
                      >
                        <div className="p-1">
                          <div
                            tabIndex={0}
                            onKeyDown={() => {}}
                            onClick={() => {
                              onSelectedLesson(lesson)
                            }}
                            role="button"
                            className="h-min-2 group flex w-full items-center justify-between space-x-2 rounded-md p-1 hover:bg-gray-100"
                          >
                            <span className="wrap inline-flex">
                              <div
                                {...provided.dragHandleProps}
                                className="flex flex-row"
                              >
                                <DragHandle />
                                {lesson.video ? (
                                  <VideoCameraIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
                                ) : lesson.quiz?.length > 0 ? (
                                  <PuzzlePieceIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
                                ) : lesson.text ? (
                                  <DocumentTextIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
                                ) : lesson.processMapId ? (
                                  <MapIcon className="mx-1 my-0.5 h-4 w-4 text-gray-400" />
                                ) : (
                                  <SparklesIcon className="mx-1 my-0.5 h-4 w-4 text-indigo-600" />
                                )}
                              </div>
                              <div
                                className={
                                  snapshot.isDragging && !snapshot.draggingOver
                                    ? 'cursor-no-drop text-red-600 line-through'
                                    : 'ml-1 text-sm font-medium text-gray-800'
                                }
                              >
                                {lesson.name}
                              </div>
                            </span>
                            <div className="flex flex-row items-center justify-end">
                              <IconButton
                                aria-label="delete"
                                className="mr-3 h-5 w-5"
                                danger
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onDeleteTask(lesson.id, lesson.name)
                                }}
                              >
                                <TrashIcon className="h-4 w-4 text-red-500" />
                              </IconButton>
                              {published(lesson) ? (
                                <Tag
                                  className={'h-3 w-3 pl-1 text-green-400'}
                                />
                              ) : (
                                <Tag
                                  className={'h-3 w-3 pl-1 text-orange-500'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  )
}

export default ActivityDetails
