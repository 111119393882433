import type React from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

interface Props {
  title?: string
  description?: string
}

const WarningLabel: React.FC<Props> = ({
  title = 'Warning!',
  description = '',
}) => {
  return (
    <div className="rounded-md bg-yellow-50 border border-yellow-500 p-1.5">
      <div className="flex">
        <ExclamationTriangleIcon
          className="h-5 w-5 text-yellow-500"
          aria-hidden="true"
        />
        <h3 className="text-xs font-medium text-yellow-800 ml-2">{title}</h3>
      </div>
      <div className="mt-2 text-yellow-700" style={{ fontSize: '11px' }}>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default WarningLabel
