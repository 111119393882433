import React, { useState } from 'react'

import { Grid2 as Grid } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import useAnalytics from 'src/lib/hooks/useAnalytics'
import { LessonType } from 'src/types/enums'

interface Props {
  lessonOptions: {
    id: string
    title: string
    description: string
    icon: React.ReactNode
    type: LessonType
    disabled?: boolean
  }[]
  handleSelect(type: LessonType)
}

const LessonSelector: React.FC<Props> = ({ lessonOptions, handleSelect }) => {
  const { trackEvent } = useAnalytics()
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)

  const nonDisabledOptionCount = lessonOptions.filter(
    (option) => !option.disabled,
  ).length

  return (
    <div className={'relative flex flex-row'}>
      <Grid container spacing={2}>
        {lessonOptions.map((option, index) => (
          <Grid size={{ xs: nonDisabledOptionCount === 4 ? 6 : 4 }} key={index}>
            <span>
              <Card
                id={option.id}
                key={index}
                onClick={() => {
                  trackEvent('Learner', 'lesson type select', {
                    lessonType: option.title,
                  })

                  if (!option.disabled) handleSelect(option.type)
                }}
                sx={{ minWidth: 210 }}
                className="group ml-6 hover:cursor-pointer hover:shadow-2xl hover:shadow-indigo-300"
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
              >
                <CardContent className="text-center">
                  {option.icon}
                  <p className="m-auto text-sm font-medium">{option.title}</p>
                  <p className="m-auto text-sm font-medium text-gray-400">
                    {option.description}
                  </p>
                </CardContent>
                <CardActions className="p-0">
                  {option.disabled ? (
                    <Button
                      disabled
                      fullWidth
                      className="bg-indigo-50 capitalize"
                    >
                      Coming Soon
                    </Button>
                  ) : (
                    <Button
                      className="w-full rounded bg-indigo-50 p-1 capitalize text-indigo-700 group-hover:bg-indigo-600 group-hover:text-white"
                      disabled={option.disabled}
                    >
                      {hoverIndex === index ? 'Select +' : 'Select'}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </span>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default LessonSelector
