import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import {
  EditLearnerCourseById,
  EditLearnerCourseByIdVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import Empty from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import EditLearnerCourse from 'src/components/Settings/LearnerCourse/EditLearnerCourse/EditLearnerCourse'

export const QUERY = gql`
  query EditLearnerCourseById($id: Int!, $isStafflinkUser: Boolean! = false) {
    learnerCourse: learnerCourse(id: $id) {
      id
      name
      createdAt
      updatedAt
      createdBy
      updatedBy
      rank
      status
      heroStorageObject {
        id
        downloadUrl
      }
      clientId
      learnerCategoryId
      learnerActivities {
        id
        name
        rank
        status
        learnerCourseId
        learnerTasks {
          id
          name
          rank
          status
          video
          text
          textLayout
          processMapId
          quiz {
            id
          }
          embedURL
        }
      }
      associatedMilestones {
        id
        goalTitle
        attachedLearnerItemId
        parentGoalId
        isTemplate
      }
      learnerMilestonesPerClient @include(if: $isStafflinkUser) {
        learnerCourseId
        clientName
        totalMilestones
      }
    }
    learnerCategories: editCourseCategoriesList {
      id
      name
    }
  }
`

export type BeforeQueryProps = {
  id: number
}

export const beforeQuery = ({ id }: BeforeQueryProps) => {
  return {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  }
}

export const Loading = () => <LoadingSpinner />

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error.message}</div>
)

type Props = CellSuccessProps<
  EditLearnerCourseById,
  EditLearnerCourseByIdVariables
>

export type LearnerCategories = Props['learnerCategories']

export type LearnerCourse = Props['learnerCourse']
export type LearnerCourseRefetch = Props['queryResult']['refetch']

export const Success: FC<Props> = ({
  learnerCourse,
  learnerCategories,
  queryResult: { refetch },
}) => {
  const [learnerCourseSafe, setLearnerCourseSafe] = useState<LearnerCourse>(
    learnerCourse
      ? {
          ...learnerCourse,
          learnerMilestonesPerClient:
            learnerCourse.learnerMilestonesPerClient || [],
        }
      : null,
  )
  useEffect(() => {
    setLearnerCourseSafe(
      learnerCourse
        ? {
            ...learnerCourse,
            learnerMilestonesPerClient:
              learnerCourse.learnerMilestonesPerClient || [],
          }
        : null,
    )
  }, [learnerCourse])
  return learnerCourseSafe ? (
    <EditLearnerCourse
      course={learnerCourseSafe}
      categories={learnerCategories}
      refetch={refetch}
    />
  ) : (
    <Empty title="No course found" />
  )
}
