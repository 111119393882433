import { CheckCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon as CheckCircleSolidIcon } from '@heroicons/react/24/solid'

import Button from 'src/components/Library/Button/Button'

import DebouncedTextArea from '../../../DebouncedTextArea'
import { LearnerQuestionChoiceEditedable } from '../EditLearnerQuestionCell'

interface EditableOption {
  isDeletable: boolean
  option: LearnerQuestionChoiceEditedable
  onSetOptionText: (target: any) => void
  onMarkOption: () => void
  onDeleteOption: () => void
}

const EditOption = ({
  isDeletable,
  option,
  onSetOptionText,
  onMarkOption,
  onDeleteOption,
}: EditableOption) => {
  return (
    <div className="flex flex-1 mt-2 ml-[34px] min-h-[44px] group items-center">
      <div
        className={`flex flex-1 ${
          option.isAnswer &&
          'border rounded border-green-600 bg-green-50 min-h-[44px]'
        }`}
        style={{
          padding: option.isAnswer ? '5px' : '6px',
        }}
      >
        <Button
          fullWidth={false}
          variant="outlined"
          className="px-2 h-8 min-w-[0px] bg-white hover:bg-white !border-gray-200 group-one"
          onClick={onMarkOption}
        >
          {!option.isAnswer ? (
            <CheckCircleIcon //change color when is marked as answer
              className="m-auto w-[18px] h-[18px] text-gray-200 group-one-hover:!text-green-600"
              data-testid="mark-quiz-option-correct"
            />
          ) : (
            <CheckCircleSolidIcon className="m-auto w-[18px] h-[18px] text-green-600" />
          )}
        </Button>
        <div className="flex flex-1 ml-1 border min-h-8 rounded border-gray-200 bg-white p-1.5 pl-3">
          <DebouncedTextArea
            inputProps={{
              className: 'flex flex-1 h-5 focus:outline-none text-sm',
              style: { resize: 'none' },
              placeholder: 'Specify an answer',
              minLength: 1,
              defaultValue: option.text,
            }}
            debounceTimeout={300}
            onChange={(value) =>
              value !== option.text ? onSetOptionText(value) : null
            }
          />
        </div>
      </div>

      {isDeletable && (
        <Button
          variant="text"
          fullWidth={false}
          className="mx-2 h-8 py-0 min-w-[0px] invisible focus:outline-none group-hover:visible"
          onClick={onDeleteOption}
        >
          <MinusCircleIcon className="text-red-500 w-6 h-6 m-auto" />
        </Button>
      )}
    </div>
  )
}

export default EditOption
