import React, { useEffect, useState } from 'react'

import { captureException } from '@sentry/browser'
import {
  EditLearnerTaskById,
  EditLearnerTaskByIdVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import ProcessMapTaskForm from 'src/components/Settings/LearnerTask/LearnerTaskForms/ProcessMapTaskForm/ProcessMapTaskForm'
import TaskTypeLabel from 'src/components/TaskTypeLabel/TaskTypeLabel'
import {
  EDIT_LEARNER_TASK_BY_ID,
  UPDATE_TASK_MUTATION,
} from 'src/lib/queries/learner/learnerTask'
import { LessonType } from 'src/types/enums'

import {
  LearnerCourse,
  LearnerCourseRefetch,
} from '../../LearnerCourse/EditLearnerCourseCell'
import EmbedTaskForm from '../LearnerTaskForms/EmbedTaskForm/EmbedTaskForm'
import FormHeader from '../LearnerTaskForms/FormHeader/FormHeader'
import QuizTaskForm from '../LearnerTaskForms/QuizTaskForm/QuizTaskForm'
import VideoTaskForm from '../LearnerTaskForms/VideoTaskForm/VideoTaskForm'

interface Props {
  taskId: number
  learnerCourseId: number
  taskType: LessonType
  selectedActivityId: number
  course: LearnerCourse
  onDeleteTask(id: any, name: any)
  togglePublished(learnerItem: any)
  refetch: LearnerCourseRefetch
  selectedActivity: any
  setSelectedActivity: any
}

const EditLearnerTask: React.FC<Props> = ({
  taskId,
  taskType,
  onDeleteTask,
  selectedActivityId,
  togglePublished,
  course,
  refetch,
  selectedActivity,
  setSelectedActivity,
}) => {
  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState(false)
  const [disablePublish, setDisablePublish] = useState(true)
  const activityIndex = course
    ? course.learnerActivities.findIndex(
        (activity) => activity.id === selectedActivityId,
      )
    : 0

  const [updateLearnerTask, { loading, error }] = useMutation(
    UPDATE_TASK_MUTATION,
    {
      onCompleted: async () => {
        await refetch()
        setSaving(false)
      },
      onError: (error) => {
        toast.error(error.message)
      },
    },
  )

  let learnerTask
  {
    const { data, error } = useQuery<
      EditLearnerTaskById,
      EditLearnerTaskByIdVariables
    >(EDIT_LEARNER_TASK_BY_ID, {
      variables: { id: taskId },
      skip: !taskId,
    })
    if (error) captureException(error)
    learnerTask = data?.learnerTask
  }

  const onSave = (input, id) => {
    const castInput = Object.assign(input, {
      learnerActivityId: input.learnerActivityId,
    })
    updateLearnerTask({ variables: { id, input: castInput } })

    if (lessonTypeText === 'Video' || lessonTypeText === 'Text') {
      // handle is the user swaps between video and text lesson types
      // they might do this by removing the video and typing in text instead
      let contentTypeValue: string
      if (castInput.video || castInput.text) {
        castInput.video
          ? (contentTypeValue = 'Video')
          : (contentTypeValue = 'Text')
      }

      if (contentTypeValue) {
        setLessonTypeText(contentTypeValue)
      }
    }

    if (selectedActivity.id !== input.learnerActivityId) {
      // handle if the user changes the chapter that the lesson is in
      const newSelectedActivity = {
        ...selectedActivity,
        id: input.learnerActivityId,
      }
      setSelectedActivity(newSelectedActivity)
    }
  }

  const [lessonTypeText, setLessonTypeText] = useState(null)

  useEffect(() => {
    let contentTypeValue: any
    contentTypeValue =
      LessonType[taskType as unknown as keyof typeof LessonType]

    if (contentTypeValue === 'processMap') {
      contentTypeValue = 'Process Map'
    } else {
      contentTypeValue =
        contentTypeValue.charAt(0).toUpperCase() + contentTypeValue.slice(1)
    }

    // contentTypeValue = i.e 'Video', 'Quiz ...
    setLessonTypeText(contentTypeValue)
  }, [taskType])

  useEffect(() => {
    // asses whether the selected lesson can be published or not
    // we want to not allow the user to publish under certain circumstances
    // default is that user can't publish

    setDisablePublish(true)
    if (
      learnerTask?.video ||
      learnerTask?.text ||
      learnerTask?.processMapId ||
      learnerTask?.embedURL
    ) {
      setDisablePublish(false)
    } else if (learnerTask?.quiz?.length > 0) {
      const allQuizzesHaveAnswer = learnerTask.quiz.every((quiz) => {
        const quizChoices = quiz.choices
        const isAnswerArray = quizChoices.map((item) => item.isAnswer)
        return isAnswerArray.some((item) => item === true)
      })

      if (allQuizzesHaveAnswer) {
        // only allow publish if there is at least one answer selected as correct in each quiz
        setDisablePublish(false)
      }
    }
  }, [learnerTask])

  return (
    <>
      <TaskTypeLabel parentClassName="mb-6" lessonTypeText={lessonTypeText} />
      <FormHeader
        task={learnerTask}
        onDeleteTask={onDeleteTask}
        togglePublished={togglePublished}
        saving={saving}
        editing={editing}
        disablePublish={disablePublish}
        lessonTypeText={lessonTypeText}
      />
      {(lessonTypeText === 'Video' || lessonTypeText === 'Text') && (
        <VideoTaskForm
          options={course?.learnerActivities}
          learnerTask={learnerTask}
          onSave={onSave}
          error={error}
          loading={loading}
          setSaving={setSaving}
          setEditing={setEditing}
          activityIndex={activityIndex}
        />
      )}
      {lessonTypeText === 'Quiz' && (
        <QuizTaskForm
          options={course?.learnerActivities}
          learnerTask={learnerTask}
          onSave={onSave}
          error={error}
          loading={loading}
          setSaving={setSaving}
          setEditing={setEditing}
          activityIndex={activityIndex}
          refetch={refetch}
        />
      )}
      {lessonTypeText === 'Process Map' && (
        <ProcessMapTaskForm
          options={course?.learnerActivities}
          learnerTask={learnerTask}
          activityIndex={activityIndex}
          setSaving={setSaving}
          setEditing={setEditing}
          onSave={onSave}
        />
      )}
      {lessonTypeText === 'Embed' && (
        <EmbedTaskForm
          options={course?.learnerActivities}
          learnerTask={learnerTask}
          onSave={onSave}
          error={error}
          loading={loading}
          setSaving={setSaving}
          setEditing={setEditing}
          activityIndex={activityIndex}
        />
      )}
    </>
  )
}

export default EditLearnerTask
