export const CREATE_ACTIVITY_MUTATION = gql`
  mutation CreateLearnerActivityMutation($input: CreateLearnerActivityInput!) {
    createLearnerActivity(input: $input) {
      id
      rank
    }
  }
`

export const UPDATE_ACTIVITY_MUTATION = gql`
  mutation UpdateLearnerActivityMutation(
    $id: Int!
    $input: UpdateLearnerActivityInput!
  ) {
    updateLearnerActivity(id: $id, input: $input) {
      id
      name
      rank
      status
      updatedBy
      learnerCourseId
    }
  }
`

export const DELETE_ACTIVITY_MUTATION = gql`
  mutation DeleteLearnerActivityMutation($id: Int!) {
    deleteLearnerActivity(id: $id) {
      id
    }
  }
`
export default {
  CREATE_ACTIVITY_MUTATION,
  UPDATE_ACTIVITY_MUTATION,
  DELETE_ACTIVITY_MUTATION,
}
