import {
  DocumentTextIcon,
  MapIcon,
  PuzzlePieceIcon,
  SparklesIcon,
  VideoCameraIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'

const TaskTypeLabel = (props) => {
  const { parentClassName, lessonTypeText } = props

  const renderIcon = () => {
    switch (lessonTypeText) {
      case 'Video':
        return <VideoCameraIcon className="my-0.5 mx-1 text-gray-400 w-4 h-4" />
      case 'Quiz':
        return <PuzzlePieceIcon className="my-0.5 mx-1 text-gray-400 w-4 h-4" />
      case 'Process Map':
        return <MapIcon className="my-0.5 mx-1 text-gray-400 w-4 h-4" />
      case 'Text':
        return (
          <DocumentTextIcon className="my-0.5 mx-1 text-gray-400 w-4 h-4" />
        )
      case 'Embed':
        return <CodeBracketIcon className="my-0.5 mx-1 text-gray-400 w-4 h-4" />
      default:
        return <SparklesIcon className="my-0.5 mx-1 text-indigo-600 w-4 h-4" />
    }
  }

  return (
    <div className={parentClassName}>
      <div className="flex flex-row justify-center items-center p-0.5 px-2.5 space-x-1 absolute w-18 h-5 bg-indigo-100 rounded-full ml-14">
        {renderIcon()}
        <span className="w-8.5 h-4 text-xs leading-4 font-medium text-center text-indigo-800 whitespace-nowrap flex-none order-1 flex-grow-0">
          {lessonTypeText}
        </span>
      </div>
    </div>
  )
}

export default TaskTypeLabel
