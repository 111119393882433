import type React from 'react'

interface LearnerTranscriptIconProps {
  textLayout: string
}

const LearnerTranscriptIcon: React.FC<LearnerTranscriptIconProps> = ({
  textLayout,
}) => (
  <svg
    width="166"
    height="119"
    viewBox="0 0 166 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="166" height="119" rx="4" fill="white" />
    <rect x="8" y="9" width="105" height="70" rx="4" fill="#F3F4F6" />
    <rect
      x="118"
      y="8"
      width="43"
      height="104"
      rx="4"
      fill={textLayout === 'transcript' ? '#C7D2FE' : '#F3F4F6'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61 52C65.4183 52 69 48.4183 69 44C69 39.5817 65.4183 36 61 36C56.5817 36 53 39.5817 53 44C53 48.4183 56.5817 52 61 52ZM60.5547 41.168C60.2478 40.9634 59.8533 40.9443 59.5281 41.1183C59.203 41.2923 59 41.6312 59 42V46C59 46.3688 59.203 46.7077 59.5281 46.8817C59.8533 47.0557 60.2478 47.0366 60.5547 46.8321L63.5547 44.8321C63.8329 44.6466 64 44.3344 64 44C64 43.6656 63.8329 43.3534 63.5547 43.168L60.5547 41.168Z"
      fill="#9CA3AF"
    />
    <line
      x1="125.5"
      y1="26.5"
      x2="154.5"
      y2="26.5"
      stroke={textLayout === 'transcript' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="125.5"
      y1="40.5"
      x2="154.5"
      y2="40.5"
      stroke={textLayout === 'transcript' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="125.5"
      y1="54.5"
      x2="154.5"
      y2="54.5"
      stroke={textLayout === 'transcript' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="125.5"
      y1="68.5"
      x2="154.5"
      y2="68.5"
      stroke={textLayout === 'transcript' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="125.5"
      y1="82.5"
      x2="154.5"
      y2="82.5"
      stroke={textLayout === 'transcript' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="125.5"
      y1="96.5"
      x2="146.5"
      y2="96.5"
      stroke={textLayout === 'transcript' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

export default LearnerTranscriptIcon
