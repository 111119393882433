import { useState, useEffect, FC } from 'react'

import { Skeleton } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import {
  EditLearnerQuestionById,
  EditLearnerQuestionByIdVariables,
} from 'types/graphql'

import { CellSuccessProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  UPDATE_QUESTION_MUTATION,
  DELETE_QUESTION_MUTATION,
} from 'src/lib/queries/learner/learnerQuestion'
import { getDraggableItemStyle } from 'src/Util'

import { LearnerCourseRefetch } from '../../LearnerCourse/EditLearnerCourseCell'
import EditQuestion from '../EditQuestion'

export const QUERY = gql`
  query EditLearnerQuestionById($questionId: Int!) {
    learnerQuestion: learnerQuestion(id: $questionId) {
      id
      question
      choices
      rank
    }
  }
`

export const Loading = () => <Skeleton animation="wave" className="p-10 mb-4" />

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error.message}</div>
)
type Props = CellSuccessProps<
  EditLearnerQuestionById,
  EditLearnerQuestionByIdVariables
> & {
  refetch: LearnerCourseRefetch
  isMultipleQuestions: boolean
  questionIndex: number
}
export type LearnerQuestion = Props['learnerQuestion']
export type LearnerQuestionChoice = LearnerQuestion['choices'][0]
export type LearnerQuestionChoiceEditedable = Exclude<
  LearnerQuestionChoice,
  string | boolean | number
> & {
  isAnswer?: boolean
  rank?: number
  text?: string
}
export const Success: FC<Props> = ({
  questionIndex,
  learnerQuestion,
  isMultipleQuestions,
  refetch,
}) => {
  const [question, setQuestion] = useState<LearnerQuestion>(learnerQuestion)
  const questionHasChoices = useEffect(() => {
    setQuestion(learnerQuestion)
  }, [])

  const [updateLearnerQuestion] = useMutation(UPDATE_QUESTION_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Question updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [deleteLearnerQuestion] = useMutation(DELETE_QUESTION_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Question deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const prefill = () => {
    const prefilledQuestion = {
      question: question?.question,
      choices: [
        ...(question && question.choices),
      ] as LearnerQuestionChoiceEditedable[],
    }
    return prefilledQuestion
  }

  const onSave = ({ input, id }) => {
    updateLearnerQuestion({ variables: { id, input } })
  }

  const setQuestionText = (value) => {
    onSave({
      id: learnerQuestion.id,
      input: { ...prefill(), question: value },
    })
    setQuestion({ ...question, question: value })
  }

  const deleteQuestion = () => {
    deleteLearnerQuestion({
      variables: {
        id: learnerQuestion.id,
      },
    })
  }

  const addOption = () => {
    const questionWithAddedOption = prefill()
    questionWithAddedOption.choices = [
      ...questionWithAddedOption.choices,
      {
        text: 'New Option',
        rank: question.choices.length + 1,
        isAnswer: false,
      },
    ]

    onSave({
      id: learnerQuestion.id,
      input: questionWithAddedOption,
    })
    setQuestion({
      ...question,
      choices: questionWithAddedOption.choices,
    })
  }

  const setOptionText = (value: string, oInd: number) => {
    const questionOptionText = prefill()
    questionOptionText.choices[oInd] = {
      ...questionOptionText.choices[oInd],
      text: value,
    }

    onSave({
      id: learnerQuestion.id,
      input: questionOptionText,
    })
    setQuestion({
      ...question,
      choices: questionOptionText.choices,
    })
  }

  const markOption = (oInd: number) => {
    const questionAnswerMarking = prefill()
    questionAnswerMarking.choices[oInd] = {
      ...(questionAnswerMarking.choices[
        oInd
      ] as LearnerQuestionChoiceEditedable),
      isAnswer: !questionAnswerMarking.choices[oInd].isAnswer,
    }

    onSave({
      id: learnerQuestion.id,
      input: questionAnswerMarking,
    })
    setQuestion({
      ...question,
      choices: questionAnswerMarking.choices,
    })
  }

  const deleteOption = (oInd) => {
    oInd++
    const questionWithDeletedOption = prefill()
    questionWithDeletedOption.choices = questionWithDeletedOption.choices
      .filter((choice) => choice.rank !== oInd)
      .map((choice) =>
        choice.rank > oInd ? { ...choice, rank: choice.rank - 1 } : choice,
      )

    onSave({
      id: question.id,
      input: questionWithDeletedOption,
    })
    setQuestion({
      ...question,
      choices: questionWithDeletedOption.choices,
    })
  }

  return (
    <>
      <Draggable
        key={learnerQuestion.id}
        draggableId={learnerQuestion.id + ''}
        index={questionIndex}
      >
        {(provided, snapshot) => {
          if (snapshot.isDragging) {
            const style = provided.draggableProps.style
            // @ts-expect-error: TODO: fix this
            style.left = style.offsetLeft
            // @ts-expect-error: TODO: fix this
            style.top = style.offsetTop
          }
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={getDraggableItemStyle(provided.draggableProps.style)}
            >
              <EditQuestion
                question={question}
                onDeleteQuestion={deleteQuestion}
                onAddOption={addOption}
                onSetQuestionText={setQuestionText}
                setOptionText={setOptionText}
                markOption={markOption}
                deleteOption={deleteOption}
                isMultipleQuestions={isMultipleQuestions}
                dragHandleProps={provided.dragHandleProps}
              />
            </div>
          )
        }}
      </Draggable>
    </>
  )
}
