import { ChangeEvent, useEffect, useState } from 'react'

import { TextareaAutosize } from '@mui/base/TextareaAutosize'

import useDebounce from '../../lib/hooks/UseDebounce'

interface debouncedTextArea {
  onChange: (event) => void
  debounceTimeout?: number
  inputProps?: any
}

const DebouncedTextArea = ({
  onChange,
  debounceTimeout,
  inputProps,
}: debouncedTextArea) => {
  const [value, setValue] = useState<string>(inputProps.defaultValue || '')
  const debouncedValue = useDebounce<string>(value, debounceTimeout || 750)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  // Fetch API (optional)
  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue])

  return (
    <TextareaAutosize maxRows={4} {...inputProps} onChange={handleChange} />
  )
}

export default DebouncedTextArea
