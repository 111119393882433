import type React from 'react'

interface LearnerFullWidthIconProps {
  textLayout: string
}

const LearnerFullWidthIcon: React.FC<LearnerFullWidthIconProps> = ({
  textLayout,
}) => (
  <svg
    width="166"
    height="119"
    viewBox="0 0 166 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="166" height="119" rx="4" fill="white" />
    <rect x="30" y="9" width="105" height="70" rx="4" fill="#F3F4F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83 52C87.4183 52 91 48.4183 91 44C91 39.5817 87.4183 36 83 36C78.5817 36 75 39.5817 75 44C75 48.4183 78.5817 52 83 52ZM82.5547 41.168C82.2478 40.9634 81.8533 40.9443 81.5281 41.1183C81.203 41.2923 81 41.6312 81 42V46C81 46.3688 81.203 46.7077 81.5281 46.8817C81.8533 47.0557 82.2478 47.0366 82.5547 46.8321L85.5547 44.8321C85.8329 44.6466 86 44.3344  86 44C86 43.6656 85.8329 43.3534 85.5547 43.168L82.5547 41.168Z"
      fill="#9CA3AF"
    />
    <rect
      x="30"
      y="84"
      width="105"
      height="32"
      rx="4"
      fill={textLayout === 'fullWidth' ? '#C7D2FE' : '#F3F4F6'}
    />
    <line
      x1="37.5"
      y1="93.5"
      x2="124.5"
      y2="93.5"
      stroke={textLayout === 'fullWidth' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="37.5"
      y1="107.5"
      x2="124.5"
      y2="107.5"
      stroke={textLayout === 'fullWidth' ? '#4F46E5' : '#9CA3AF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

export default LearnerFullWidthIcon
