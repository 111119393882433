export const CREATE_QUESTION_MUTATION = gql`
  mutation CreateLearnerQuestionMutation($input: CreateLearnerQuestionInput!) {
    createLearnerQuestion(input: $input) {
      id
      question
      rank
      choices
      createdBy
      updatedBy
    }
  }
`

export const UPDATE_QUESTION_MUTATION = gql`
  mutation UpdateLearnerQuestionMutation(
    $id: Int!
    $input: UpdateLearnerQuestionInput!
  ) {
    updateLearnerQuestion(id: $id, input: $input) {
      id
      question
      rank
      choices
    }
  }
`

export const DELETE_QUESTION_MUTATION = gql`
  mutation DeleteLearnerQuestionMutation($id: Int!) {
    deleteLearnerQuestion(id: $id) {
      id
    }
  }
`

export default {
  CREATE_QUESTION_MUTATION,
  UPDATE_QUESTION_MUTATION,
  DELETE_QUESTION_MUTATION,
}
