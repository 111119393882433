import React, { useState, useEffect } from 'react'

import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Grid2 as Grid, TextField as MuiTextField } from '@mui/material'
import Button from '@mui/material/Button'
import { LearnerTask } from 'types/graphql'

import { Form, Label } from '@redwoodjs/forms'

import DebounceTextInput from 'src/components/DebounceTextInput'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'

import ProcessMapTaskCell from './ProcessMapTaskCell'

interface Data {
  text?: string
  learnerActivityId?: number
  name?: string
  processMapId?: number
}

interface Option {
  id: number
  name?: string
}
interface Props {
  learnerTask: LearnerTask
  onSave?(input: any, id: number)
  error?: boolean
  loading?: boolean
  options: Option[]
  activityIndex: number
  setSaving(bool: boolean)
  setEditing(bool: boolean)
}

const ProcessMapTaskForm: React.FC<Props> = ({
  learnerTask,
  onSave,
  setEditing,
  setSaving,
  activityIndex,
  options,
}) => {
  const [visibleText, setVisibleText] = useState<boolean>(
    learnerTask?.text ? true : false,
  )

  const [selectedActivityId, setSelectedActivityId] = useState<number>(-1)

  const [chapterValue, setChapterValue] = useState<Option | null>(
    options[activityIndex],
  )
  const [lessonTitle, setLessonTitle] = useState<string>('')
  const [mapSelected, setMapSelected] = useState<boolean>(false)
  const [updateData, setUpdateData] = useState<boolean>(false)
  const [textEditorValue, setTextEditorValue] = useState<string>('')

  const onChange = () => {
    const data: Data = {}

    if (selectedActivityId === -1) {
      data.learnerActivityId = learnerTask?.learnerActivityId
    } else {
      data.learnerActivityId = selectedActivityId
    }

    if (!lessonTitle) {
      data.name = ''
    } else if (lessonTitle !== learnerTask?.name) {
      data.name = lessonTitle
    } else {
      data.name = learnerTask.name
    }

    if (textEditorValue) {
      data.text = textEditorValue
    } else {
      data.text = ''
    }

    if (learnerTask) {
      if (
        learnerTask.text === data.text &&
        learnerTask.learnerActivityId === data.learnerActivityId &&
        learnerTask.name === data.name
      ) {
        setEditing(false)
        setUpdateData(false)
        return
      }
      setSaving(true)
      setEditing(false)
      onSave(data, learnerTask?.id)
    }
    setSelectedActivityId(-1)
    setUpdateData(false)
  }

  useEffect(() => {
    if (learnerTask?.name && lessonTitle !== learnerTask.name) {
      setLessonTitle(learnerTask.name)
    }
  }, [learnerTask?.name])

  const handleTitleChange = (value) => {
    setLessonTitle(value)
    setUpdateData(true)
  }

  const handleDebounceRichTextEditorChange = (value) => {
    setTextEditorValue(value)
    setUpdateData(true)
  }

  useEffect(() => {
    if (updateData) {
      setEditing(true)
      onChange()
    }
  }, [updateData])

  // prevent save on task change
  useEffect(() => {
    setUpdateData(false)
  }, [learnerTask?.id])

  return (
    <div className="px-4">
      <Form>
        <Grid container rowSpacing={1} columnSpacing={0.5} className="pl-10">
          <Grid size={{ xs: 2 }}>
            <Label name="learnerActivityTitle" className="rw-label">
              Title
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <DebounceTextInput
              setKey={learnerTask?.id}
              name="title"
              placeholder="Title of the lesson"
              className="mt-4"
              defaultValue={learnerTask?.name}
              debounceTimeout={1500}
              onChange={(value) => {
                return value !== learnerTask?.name
                  ? handleTitleChange(value)
                  : null
              }}
              isRequired={true}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Label
              name="learnerActivityId"
              className="rw-label"
              errorClassName="rw-label rw-label-error"
            >
              Chapter
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }} className="mt-4">
            <Autocomplete
              sx={{ '& .MuiAutocomplete-input': { height: 5 } }}
              key={learnerTask?.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disablePortal
              onChange={(_, newValue: Option) => {
                setSelectedActivityId(newValue.id)
                setChapterValue(newValue) // Update the chapterValue state here
                setUpdateData(true)
              }}
              value={chapterValue} // Use chapterValue as the value
              id="combo-box-demo"
              options={options}
              getOptionLabel={(option: Option) => option.name}
              defaultValue={chapterValue} // Use chapterValue as the defaultValue
              renderInput={(params) => <MuiTextField {...params} />}
            />
          </Grid>
          <Grid size={{ xs: 2 }}>
            <Label
              name="learnerActivityId"
              className="rw-label"
              errorClassName="rw-label rw-label-error"
            >
              Map
            </Label>
          </Grid>
          <Grid size={{ xs: 10 }}>
            <ProcessMapTaskCell
              onSave={onSave}
              learnerTask={learnerTask}
              setEditing={setEditing}
              setSaving={setSaving}
              setMapSelected={setMapSelected}
            />
          </Grid>
          {mapSelected && (
            <Grid container>
              <Grid
                container
                size={{ xs: 2 }}
                direction="row"
                columnSpacing={1.5}
              >
                <Grid size={{ xs: 2 }}>
                  <Label
                    name="text"
                    className="rw-label"
                    errorClassName="rw-label rw-label-error"
                  >
                    Text
                  </Label>
                  <Label
                    name="text-optional"
                    className="rw-label mt-1 text-xs text-gray-400"
                  >
                    Optional
                  </Label>
                </Grid>
              </Grid>
              <Grid size={{ xs: 10 }} className="mt-6">
                {visibleText ? (
                  <RichTextEditor
                    defaultValue={learnerTask?.text}
                    onChange={handleDebounceRichTextEditorChange}
                    name="text"
                    id="tools-rte"
                    debounce={800}
                    className="max-h-[200px]"
                  />
                ) : (
                  <Button
                    onClick={() => setVisibleText(true)}
                    disableRipple
                    className="border-dashed border-gray-300 normal-case text-indigo-600"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    endIcon={<PlusCircleIcon className="h-4 w-4" />}
                  >
                    Add text
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Form>
    </div>
  )
}

export default ProcessMapTaskForm
