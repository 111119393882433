import React, { useEffect } from 'react'

import { TextField } from '@mui/material'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import ProcessMapViewerCell from 'src/components/ProcessMapViewerCell'

interface Data {
  text?: string
  learnerActivityId?: number
  name?: string
  video?: string
  processMapId?: number
}
export const QUERY = gql`
  query FindProcessMapTaskQuery {
    mapList: reactMapsList {
      id
      status
      clientId
      name
      description
      isVisible
    }
  }
`

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'no-cache',
})

export const Loading = () => <LoadingSpinner />

export const Empty = () => (
  <div className="mt-6 flex items-center justify-center text-sm font-medium text-red-600">
    No Process Maps Found. Please make a process map to add one to a lesson.
  </div>
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success = ({
  mapList,
  onSave,
  setEditing,
  setSaving,
  learnerTask,
  setMapSelected,
}) => {
  const [value, setValue] = React.useState<any | null>(
    mapList.find((map) => map.id === learnerTask?.processMapId),
  )
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    const mapId = mapList.find((map) => map.id === learnerTask?.processMapId)
    setValue(mapId)
    if (mapId) {
      setMapSelected(true)
    }
  }, [learnerTask?.processMapId])

  const onChange = (newValue) => {
    const data: Data = {}
    setValue(newValue)
    data.learnerActivityId = learnerTask.learnerActivityId

    if (newValue) {
      data.processMapId = newValue.id
      setSaving(true)
      setEditing(false)
      onSave(data, learnerTask.id)
      setMapSelected(true)
    } else {
      setMapSelected(false)
    }
  }

  return (
    <div className={'mt-4'}>
      <Autocomplete
        sx={{ '& .MuiAutocomplete-input': { height: 5 } }}
        key={value?.id}
        value={value}
        onChange={(_: any, newValue: any | null) => {
          setValue(newValue)
          onChange(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id="controllable-states"
        options={mapList}
        getOptionLabel={(item: any) => item.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        // groupBy={(options: any) => options.reactMapCategory?.name}
        renderInput={(params) => <TextField {...params} />}
        data-testid="process-map-autocomplete"
      />
      <p className="mt-0.5 text-xs text-gray-400">
        Please be aware that anyone assigned to this course will be able to view
        this process map. Please ensure that there is no sensitive information
        in this map that you would like to keep private.
      </p>
      {value ? <ProcessMapViewerCell id={value.id} /> : null}
    </div>
  )
}
