export const CREATE_TASK_MUTATION = gql`
  mutation CreateLearnerTaskMutation($input: CreateLearnerTaskInput!) {
    createLearnerTask(input: $input) {
      id
      rank
    }
  }
`

export const DELETE_TASK_MUTATION = gql`
  mutation DeleteLearnerTaskMutation($id: Int!) {
    deleteLearnerTask(id: $id) {
      id
    }
  }
`
export const UPDATE_TASK_MUTATION = gql`
  mutation UpdateLearnerTaskMutation(
    $id: Int!
    $input: UpdateLearnerTaskInput!
  ) {
    updateLearnerTask(id: $id, input: $input) {
      id
      name
      video
      text
      processMapId
      rank
      status
      updatedBy
      learnerActivityId
      embedURL
    }
  }
`

export const EDIT_LEARNER_TASK_BY_ID = gql`
  query EditLearnerTaskById($id: Int!) {
    learnerTask: learnerTask(id: $id) {
      id
      name
      video
      text
      textLayout
      quiz {
        id
        question
        choices
        rank
      }
      processMapId
      rank
      status
      learnerActivityId
      showQuizAnswers
      embedURL
    }
  }
`
