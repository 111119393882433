import React, { useEffect, useState } from 'react'

import {
  DocumentTextIcon,
  MapIcon,
  PuzzlePieceIcon,
  SparklesIcon,
  VideoCameraIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import type {
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import DragHandle from 'src/components/DragHandle/DragHandle'
import Tag from 'src/components/Tag/Tag'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { getDraggableItemStyle, published, reorderItems } from 'src/Util'

import { LearnerCourseRefetch } from '../../EditLearnerCourseCell'

interface Props {
  chapter: any
  refetch: LearnerCourseRefetch
  onSelectedLesson(lesson: any, chapter: any)
}

const DraggableTask: React.FC<Props> = ({
  onSelectedLesson,
  chapter,
  refetch,
}) => {
  const [learnerTasks, setLearnerTasks] = useState(chapter?.learnerTasks)
  const [orderedLearnerTasks, setOrderedLearnerTasks] = useState<
    any[] | undefined
  >()
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setLearnerTasks(chapter?.learnerTasks)
  }, [chapter?.learnerTasks])

  const [rankLearnerItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: async () => {
      await refetch()
      setIsUpdating(false)
      setOrderedLearnerTasks(null)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (orderedLearnerTasks) {
      // setIsUpdating to true as a stop to disallow further changes until after the mutation completes
      setIsUpdating(true)
      const reorderedItemsInput = orderedLearnerTasks.map((item, index) => ({
        id: item.id,
        rank: index + 1,
      }))
      rankLearnerItems({
        variables: {
          input: { itemType: 'Task', items: reorderedItemsInput },
        },
      })
    }
  }, [orderedLearnerTasks])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    if (isUpdating) {
      return
    }

    const reorderedItems = reorderItems(
      learnerTasks,
      result.source.index,
      result.destination.index,
    )

    setLearnerTasks(reorderedItems)
    setOrderedLearnerTasks(reorderedItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={(chapter?.id ?? '0') + ''}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {learnerTasks.map((lesson, lessonIndex) => (
              <Draggable
                key={lesson.id}
                draggableId={lesson.id + ''}
                index={lessonIndex}
              >
                {(provided, snapshot) => (
                  <div
                    className="divide-gray-20 divide-y"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getDraggableItemStyle(provided.draggableProps.style)}
                    data-testid={`task-name-${lesson.name}`}
                  >
                    <div className="p-1">
                      <div
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => {
                          onSelectedLesson(lesson, chapter)
                        }}
                        role="button"
                        className="h-min-2 sl-drag-default group flex items-center justify-between rounded hover:bg-gray-100"
                      >
                        <span className="wrap relative inline-flex">
                          <div
                            {...provided.dragHandleProps}
                            className="flex flex-row"
                          >
                            <DragHandle className="sl-drag-icon absolute my-1" />
                            {lesson.video ? (
                              <VideoCameraIcon className="sl-drag-icon-hidden mx-1 my-0.5 h-4 w-4 text-gray-400" />
                            ) : lesson.quiz?.length > 0 ? (
                              <PuzzlePieceIcon className="sl-drag-icon-hidden mx-1 my-0.5 h-4 w-4 text-gray-400" />
                            ) : lesson.processMapId ? (
                              <MapIcon className="sl-drag-icon-hidden mx-1 my-0.5 h-4 w-4 text-gray-400" />
                            ) : lesson.embedURL ? (
                              <CodeBracketIcon className="sl-drag-icon-hidden mx-1 my-0.5 h-4 w-4 text-gray-400" />
                            ) : lesson.text ? (
                              <DocumentTextIcon className="sl-drag-icon-hidden mx-1 my-0.5 h-4 w-4 text-gray-400" />
                            ) : (
                              <SparklesIcon className="sl-drag-icon-hidden mx-1 my-0.5 h-4 w-4 text-indigo-600" />
                            )}
                          </div>
                          <div
                            className={
                              snapshot.isDragging && !snapshot.draggingOver
                                ? 'ml-1 cursor-no-drop text-red-600 line-through'
                                : 'ml-1 text-sm text-gray-700'
                            }
                          >
                            {lesson.name}
                          </div>
                        </span>
                        <div className="flex flex-row justify-end">
                          {published(lesson) ? (
                            <Tag
                              className={'h-3 w-3 px-0 pl-1 text-green-400'}
                            />
                          ) : (
                            <Tag
                              className={'h-3 w-3 px-0 pl-1 text-orange-500'}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableTask
