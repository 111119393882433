import { useEffect } from 'react'

import { GlobalRole } from 'types/graphql'

import { Metadata } from '@redwoodjs/web'

import EditLearnerCourseCell from 'src/components/Settings/LearnerCourse/EditLearnerCourseCell'
import { useAuth } from 'web/src/Providers'

const EditLearnerCoursePage = ({ id }) => {
  const { currentUser } = useAuth()
  const clientId = currentUser.parentData.id

  const isStafflinkUser = (
    ['STAFFLINK', 'SUPERADMIN'] as GlobalRole[]
  ).includes(currentUser.userData.role)

  return (
    <>
      <Metadata
        title="Settings - Learner - Course"
        description="Settings - Learner - Course"
      />
      <EditLearnerCourseCell
        id={id}
        clientId={clientId}
        isStafflinkUser={isStafflinkUser}
      />
    </>
  )
}

export default EditLearnerCoursePage
